import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { CONFIGPHONEROUTES } from 'src/router/Api'
export const getListConfigPhone = async (params) => {
	return await axios.get(CONFIGPHONEROUTES.name, {
		params: convertParams(params),
	})
}
export const createConfigPhone = async (body: any) => {
	return await axios.post(CONFIGPHONEROUTES.name, body)
}
export const getOneConfigPhone = async (id, params) => {
	return await axios.get(`${CONFIGPHONEROUTES.name}/${id}`, {
		params: convertParams(params),
	})
}

export const updateConfigPhone = async (id: string, body: any) => {
	return await axios.put(`${CONFIGPHONEROUTES.name}/${id}`, body)
}

export const deleteConfigPhone = async (id: string) => {
	return await axios.delete(`${CONFIGPHONEROUTES.name}/${id}`)
}
