import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { CHARTROUTES } from 'src/router/Api'
export const getChartDataUser = async (params) => {
	return await axios.get(CHARTROUTES.name, {
		params: convertParams(params),
	})
}
export const getCountUser = async (params) => {
	return await axios.get(CHARTROUTES.information, {
		params: convertParams(params),
	})
}
