/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MRT_ColumnDef, MRT_PaginationState } from 'mantine-react-table'
import { FieldType, PhoneData } from 'src/interfaces'
import { useModal } from 'src/context/ModalContext'
import { useLoading } from 'src/context/LoadingContext'
import { Flex, Stack, Switch } from '@mantine/core'
import CustomSelect from 'src/components/CustomComponents/CustomSelect'
import CustomClearInput from 'src/components/CustomComponents/CustomClearInput'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import TableComponent from 'src/components/TableComponent'
import dayjs from 'dayjs'
import { formatDateTime, size } from 'src/utils/constant'
import { filterNormal } from 'src/utils/filter'
import {
	deleteConfigPhone,
	getListConfigPhone,
	updateConfigPhone,
} from 'src/apis/configPhoneAPI'
import ModalCreatePhone from './ModalCreatePhone'

interface ConfigPhonePageListProps {
	columns: MRT_ColumnDef[]
	fields: FieldType[]
	[key: string]: any
}

function ConfigPhonePageList({ columns, fields }: ConfigPhonePageListProps) {
	const { openError, openModal } = useModal()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const [data, setData] = useState<PhoneData[]>([])
	const [pagination, setPagination] = useState<MRT_PaginationState>({
		pageIndex: 1,
		pageSize: 10,
	})
	const [value, setValue] = useState({
		field: fields[0].value,
		value: null,
	})
	const [total, setTotal] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const [open, setOpen] = useState({
		open: false,
		row: null as any,
	})
	const handleGetData = useCallback(async () => {
		let arr: PhoneData[] = []
		let count: number = 0
		let filter = {}
		setLoading(true)
		try {
			filter = filterNormal(value)
			const res = await getListConfigPhone({
				fields: ['$all'],
				page: pagination.pageIndex,
				limit: pagination.pageSize,
				where: { ...filter },
			})
			arr = res?.data?.results?.objects?.rows?.map((item, index) => ({
				...item,
				no: index + 1 + pagination.pageSize * (pagination.pageIndex - 1),
				created_at: dayjs(item?.created_at).format(formatDateTime),
				_status: item?.status ? 'Active' : 'Inactive',
			}))
			count = res?.data?.results?.objects?.count
		} catch (error) {
			openError(error)
		} finally {
			setData(arr)
			setTotal(count)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(pagination), JSON.stringify(value)])
	const handleChangePage = useCallback((value: number) => {
		setPagination((pre) => ({
			...pre,
			pageIndex: value,
		}))
	}, [])
	const handleSetValue = (e, key) => {
		if (key === 'field' && value.value === '') {
			setValue((pre) => ({ ...pre, [key]: e, value: null }))
		} else {
			setValue((pre) => ({ ...pre, [key]: e }))
		}
	}
	const handleEditPhone = useCallback((row) => {
		setOpen({ open: true, row: row?.original || null })
	}, [])
	const handleDeletePhone = useCallback(
		async (id) => {
			openLoadingContext()
			try {
				const res = await deleteConfigPhone(id)
				if (res?.data?.code === 200) {
					await handleGetData()
					openModal({
						type: 'success',
						label: `You have successfully deleted this phone !😄😄`,
					})
				}
			} catch (error) {
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		[handleGetData],
	)
	const handleDelete = useCallback(
		(row: any) => {
			openModal({
				type: 'confirm',
				label: <div>Do you want to delete this phone ?</div>,
				handleConfirm: () => {
					handleDeletePhone(row?.original?.id)
				},
			})
		},
		[handleDeletePhone],
	)
	const handleUpdateStatus = useCallback(
		async (id: string, status: boolean) => {
			openLoadingContext()
			try {
				const res = await updateConfigPhone(id, { status })
				if (res) {
					setData((pre) =>
						pre.map((item) => (item?.id === id ? { ...item, status } : item)),
					)
					openModal({
						type: 'success',
						label: 'Phone status updated successfully!✔️✔️✔️ ',
					})
				}
			} catch (error) {
				openError(error)
			} finally {
				closeLoadingContext()
			}
		},
		[JSON.stringify(data)],
	)
	const handleConfirmUpdate = useCallback(
		(row: PhoneData) => {
			const { id, status } = row
			openModal({
				type: 'confirm',
				label: (
					<div>
						Do you want to {status ? 'inactive' : 'active'} this phone ?
					</div>
				),
				handleConfirm: () => {
					handleUpdateStatus(id, !status)
				},
			})
		},
		[handleUpdateStatus],
	)
	const statusCol = useMemo(
		(): MRT_ColumnDef => ({
			accessorKey: 'status',
			header: 'Status',
			maxSize: size.no,
			Cell: ({ cell, row }) => {
				return (
					<Switch
						onLabel="Active"
						offLabel="InActive"
						radius="sm"
						size="xl"
						sx={{
							'.mantine-Switch-body': {
								'.mantine-Switch-track': {
									cursor: 'pointer',
									width: 100,
								},
							},
							'.mantine-Switch-trackLabel': {
								fontSize: '0.875rem',
							},
						}}
						checked={cell.getValue<boolean>()}
						onChange={() => handleConfirmUpdate(row.original as PhoneData)}
					/>
				)
			},
		}),
		[handleConfirmUpdate],
	)
	const columnsNew = useMemo(
		() => [...columns.slice(0, 3), statusCol, ...columns.slice(3)],
		[statusCol, columns],
	)
	useEffect(() => {
		handleGetData()
	}, [JSON.stringify(pagination)])
	useEffect(() => {
		let timer
		if (value.value !== null) {
			timer = setTimeout(() => {
				if (pagination.pageIndex === 1) {
					handleGetData()
				} else handleChangePage(1)
			}, 500)
		}
		return () => clearTimeout(timer)
	}, [JSON.stringify(value)])
	return (
		<Stack sx={{ width: '100%' }}>
			<Flex gap={8} justify="space-between" sx={{ flexWrap: 'wrap' }}>
				<Flex gap={8} sx={{ flexWrap: 'wrap' }}>
					<CustomSelect
						value={value.field}
						data={fields}
						onChange={(e) => {
							handleSetValue(e, 'field')
						}}
						sx={{
							input: {
								color: 'black !important',
								fontWeight: 600,
							},
						}}
						disabled
					/>
					<CustomClearInput
						value={value.value}
						onChange={(e) => handleSetValue(e, 'value')}
						placeholder={'Search'}
					/>
				</Flex>
				<CustomButton
					variant="outlined"
					sx={{ height: '36px', whiteSpace: 'nowrap', minWidth: '240px' }}
					onClick={() => {
						setOpen({ open: true, row: null })
					}}
				>
					Create
				</CustomButton>
			</Flex>
			<TableComponent
				columns={columnsNew}
				total={total}
				data={data}
				pagination={pagination}
				loading={loading}
				handleChangePage={handleChangePage}
				handleEdit={handleEditPhone}
				handleDelete={handleDelete}
			/>
			{open.open && (
				<ModalCreatePhone
					open={open}
					handleGetData={handleGetData}
					onClose={() => setOpen({ open: false, row: null })}
					pagination={pagination}
					handleChangePage={handleChangePage}
				/>
			)}
		</Stack>
	)
}

export default ConfigPhonePageList
