/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { Carousel } from '@mantine/carousel';
import { Carousel } from '@mantine/carousel';
import { Text, Container, useMantineTheme, Title } from '@mantine/core';
import '../Styles/SectionOne.scss';

const SectionOne = () => {
    const theme = useMantineTheme();

    const carouselContent = ({url = 'src/images/section1/section-1-1.png'}:{url: string}) => ({
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        // backgroundColor: theme.colors.green[5],
        borderRadius: 15,
        // backgroundImage: `url("https://images.unsplash.com/photo-1510798831971-661eb04b3739?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80")`,
        // backgroundImage: `url(${require('src/images/section1/section-1-1.png')})`,
        // backgroundImage: `url(${require('src/images/section1/section-1-1.png')})`,
        backgroundImage: `url(${require('src/images/onboarding-1.png')})`,
        // gap: 15
    });

    return (
        <section id="section-one">
            <Container>
                <Text color="black" align="center" mb="15px">
                    <Title order={1}>Every thing you can do in UniVini App</Title>
                </Text>

                <Text color="black" align="center" mb="25px">
                    Just download and get started
                </Text>

                <Carousel
                    withIndicators
                    height={300}
                    slideSize="33.333333%"
                    slideGap="md"
                    breakpoints={[
                        { maxWidth: 'md', slideSize: '50%' },
                        { maxWidth: 'sm', slideSize: '100%', slideGap: 15 },
                    ]}
                    loop
                    align="start"
                    pr="10px"
                    pl="10px"
                >
                    <Carousel.Slide>
                        <div style={carouselContent({url: 'src/images/section1/section-1-1.png'})}>
                            <Title order={2}>Event and Club</Title>
                            <Text>Easy to join or create groups</Text>
                        </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <div style={carouselContent({url: 'src/images/section1/section-1-1.png'})}>
                            <Title order={2}>Trail</Title>
                            <Text>Tracking your hiking's progress</Text>
                        </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <div style={carouselContent({url: 'src/images/section1/section-1-1.png'})}>
                            <Title order={2}>Talk</Title>
                            <Text>Talk with your friends</Text>
                        </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <div style={carouselContent({url: 'src/images/section1/section-1-1.png'})}>
                            <Title order={2}>Community</Title>
                            <Text>Connect with others</Text>
                        </div>
                    </Carousel.Slide>
                </Carousel>
            </Container>
        </section>
    );
};

export default SectionOne;