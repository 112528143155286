import React, {
	createContext,
	useState,
	useContext,
	useCallback,
	useEffect,
} from 'react'
import { useLocation } from 'react-router-dom'
import ModalConfirm from 'src/components/Modal/ModalConfirm'
import ModalError from 'src/components/Modal/ModalError'
import ModalSuccess from 'src/components/Modal/ModalSuccess'

const ModalContext = createContext({
	// eslint-disable-next-line no-empty-pattern
	openModal: ({}) => {},
	openError: (_error) => {},
	closeModal: () => {},
})
export const ModalProvider = ({ children }) => {
	const [open, setOpen] = useState() as any

	const openModal = useCallback(({ type = 'confirm', ...others }) => {
		setOpen({ type, ...others })
	}, [])
	const openError = useCallback((error) => {
		setOpen({ type: 'error', error: error })
	}, [])
	const closeModal = useCallback(() => {
		setOpen('')
	}, [])
	const location = useLocation()

	useEffect(() => {
		closeModal()
	}, [location.pathname, closeModal])
	return (
		<ModalContext.Provider
			value={{
				openModal,
				closeModal,
				openError,
			}}
		>
			{children}
			{open?.type === 'confirm' && (
				<ModalConfirm open={true} handleClose={closeModal} {...open} />
			)}
			{open?.type === 'success' && (
				<ModalSuccess open={true} handleClose={closeModal} {...open} />
			)}
			{open?.type === 'error' && (
				<ModalError open={true} handleClose={closeModal} {...open} />
			)}
		</ModalContext.Provider>
	)
}

export const useModal = () => useContext(ModalContext)
