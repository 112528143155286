/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Container, Title, Text, Button, Image, Stack } from '@mantine/core';
import { FaApple, FaGooglePlay } from 'react-icons/fa';

const DownloadAppSection = () => {
  return (
    <Container size="sm" style={{ textAlign: 'center', padding: '50px 0' }} id="link-app" >
      <Title order={2}>Download Our App</Title>
      <Text size="md" color="dimmed" mb="lg">
        Get our app for the best experience on iOS and Android devices.
      </Text>

      <Stack align="center" spacing="sm">
        {/* iOS Download Button */}
        <a href="https://apps.apple.com/us/app/univini/id6554002242" target="_blank">
          <Image src={require('src/images/appstore.png')} alt={'sample1'} style={{ width: '250px' }} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.vnconnections.app&referrer=YTAISTAS" target="_blank" >
          <Image src={require('src/images/googleplay.png')} alt={'sample1'} style={{ width: '250px' }} />
        </a>
      </Stack>
    </Container>
  );
};

export default DownloadAppSection;
