import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import SideBar from 'src/components/SideBar'
import Header from 'src/components/Header'
import { useNavigate } from 'react-router-dom'
import styles from './DefaultLayout.module.scss'
function DefaultLayout({ children }) {
	const navigate = useNavigate()
	const [show, setShow] = useState(false)
	useEffect(() => {
		const info = JSON.parse(localStorage.getItem('info'))
		const token = JSON.parse(localStorage.getItem('token'))
		const refresh_token = JSON.parse(localStorage.getItem('refresh_token'))
		
		if (!info || !token || !refresh_token) {
			localStorage.clear()
			navigate('/login')
		}
	}, [navigate])
	return (
		<Stack direction="row" className={styles.wrapprer}>
			<SideBar show={show} setShow={setShow} />
			<Box flex={1} className={styles.body}>
				<Header setShow={setShow} />
				<Grid
					container
					style={{
						minHeight: 'calc(100vh - 50px)',
						width: '100%',
						padding: 16,
					}}
					onClick={() => setShow(false)}
				>
					{children}
				</Grid>
			</Box>
		</Stack>
	)
}

export default DefaultLayout
