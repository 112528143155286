import React, { useCallback, useState } from 'react'
import styles from './Header.module.scss'
import { Avatar, Menu, Stack, MenuItem } from '@mui/material'
import { FaAlignJustify } from 'react-icons/fa'
import cmsDefaultAva from 'src/images/defaultAva.png'
import { useNavigate } from 'react-router-dom'
import { LuLogOut } from 'react-icons/lu'
import { GoGear } from 'react-icons/go'
import ModalChangePassword from '../Modal/ModalChangePassword'
import { useModal } from 'src/context/ModalContext'
import { logout } from 'src/apis/authAPI'

function Header({ setShow }) {
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = useState(null)
	const [openModal, setOpenModal] = useState(false)
	const { openModal: openModalConfirm } = useModal()

	const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const handleLogout = useCallback(async () => {
		try {
			await logout({
				refresh_token: JSON.parse(localStorage.getItem('refresh_token')),
				access_token: JSON.parse(localStorage.getItem('token')),
			})
		} catch (error) {
		} finally {
			localStorage.clear()
			navigate('/login')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleConfirm = () => {
		openModalConfirm({
			type: 'confirm',
			label: <div>Do you want to logout ?😢😢</div>,
			handleConfirm: () => {
				handleLogout()
			},
		})
	}
	const handleOpenModal = useCallback(() => setOpenModal(true), [])
	const handleCloseModal = useCallback(() => setOpenModal(false), [])
	return (
		<div>
			<Stack direction="row" className={styles.header}>
				<FaAlignJustify
					className={styles.toggleSideBar}
					onClick={() => setShow((pre) => !pre)}
				/>
				<Stack direction="row" justifyContent="flex-end" sx={{ flex: 1 }}>
					<Avatar
						src={cmsDefaultAva}
						onClick={handleClick}
						sx={{ cursor: 'pointer' }}
					/>
				</Stack>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					className={styles.menuContainer}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<MenuItem
						onClick={handleOpenModal}
						sx={{
							'&.Mui-focusVisible': {
								backgroundColor: 'transparent',
								':hover': {
									backgroundColor: 'rgba(0, 0, 0, 0.04)',
								},
							},
						}}
					>
						<Stack
							direction="row"
							spacing={1}
							justifyContent="space-between"
							alignItems="center"
						>
							<GoGear />
							<span>Change password</span>
						</Stack>
					</MenuItem>
					<MenuItem onClick={handleConfirm}>
						<Stack
							direction="row"
							spacing={1}
							justifyContent="space-between"
							alignItems="center"
						>
							<LuLogOut />
							<span>Logout</span>
						</Stack>
					</MenuItem>
				</Menu>
			</Stack>
			{openModal && (
				<ModalChangePassword open={openModal} handleClose={handleCloseModal} />
			)}
		</div>
	)
}

export default React.memo(Header)
