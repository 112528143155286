import axios from '../axios'
import { AUTHROUTES } from '../router/Api'
export const login = async (payload) => {
	return await axios.post(AUTHROUTES.login, payload)
}
export const changePassWord = async (payload) => {
	return await axios.put(AUTHROUTES.change_password, payload)
}
export const logout = async (payload) => {
	return await axios.post(AUTHROUTES.logout, payload)
}
export const createDynamicLink = async (payload) => {
	return await axios.post(AUTHROUTES.dynamicLink, payload)
}
