import { ApexOptions } from 'apexcharts'
import { memo } from 'react'
import ReactApexChart from 'react-apexcharts'
import { color } from 'src/utils/constant'

function Chartline({ data, categories }) {
	const series: ApexOptions['series'] = [
		{
			name: 'Users',
			data: data,
		},
	]

	const options: ApexOptions = {
		chart: {
			height: 400,
			type: 'line',
			toolbar: {
				show: true,
				offsetX: 0,
				offsetY: 0,
				tools: {
					download: false,
					selection: false,
					zoom: false,
					zoomin: true,
					zoomout: true,
					pan: false,
				},

				autoSelected: 'zoom',
			},
		},
		stroke: {
			curve: 'smooth',
		},
		xaxis: {
			type: 'category',
			categories: categories,
			tickAmount: 14,
		},
		dataLabels: {
			enabled: true,
		},
		title: {
			text: 'Users',
			align: 'left',
			style: {
				fontSize: '16px',
				color: '#666',
			},
		},
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'dark',
				gradientToColors: [color.Orange, color.CmsPrimary, color.BlueV2],
				shadeIntensity: 1,
				type: 'horizontal',
				opacityFrom: 1,
				opacityTo: 1,
				stops: [0, 33, 66, 100],
			},
		},
		yaxis: { min: 0 },
	}

	return (
		<div>
			<div id="chart">
				<ReactApexChart
					options={options}
					series={series}
					type="line"
					height={400}
				/>
			</div>
		</div>
	)
}

export default memo(Chartline)
