import { convertParams } from 'src/utils/utils'
import axios from '../axios'
import { CATEGORYTRAILS } from 'src/router/Api'
export const getListCategoryTrails = async (params) => {
	return await axios.get(CATEGORYTRAILS.name, {
		params: convertParams(params),
	})
}
export const getListTrails = async (params) => {
	return await axios.get(CATEGORYTRAILS.trail, {
		params: convertParams(params),
	})
}
export const createCategoryTrails = async (payload) => {
	return await axios.post(CATEGORYTRAILS.name, payload)
}
export const updateCategoryTrails = async (payload) => {
	return await axios.post(CATEGORYTRAILS.addTrails, payload)
}
export const getOneCategory = async (
	id,
	params = { fields: ['$all'] } as {},
) => {
	return await axios.get(`${CATEGORYTRAILS.name}/${id}`, {
		params: convertParams(params),
	})
}
export const updateCategory = async (id, payload) => {
	return await axios.put(`${CATEGORYTRAILS.name}/${id}`, payload)
}
export const deleteCategory = async (id) => {
	return await axios.delete(`${CATEGORYTRAILS.name}/${id}`)
}
