/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from '@mantine/form'
import { Flex, Stack, Textarea, TextInput } from '@mantine/core'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import { useModal } from 'src/context/ModalContext'
import {
	getOneCategory,
	updateCategory,
	updateCategoryTrails,
} from 'src/apis/categoryTrailsAPI'
import { useLoading } from 'src/context/LoadingContext'
import TrailItem from 'src/components/Trail/TrailItem'
import ModalTrails from './ModalTrails'
import CustomGoBack from 'src/components/CustomComponents/CustomGoBack'
import { useNavigate } from 'react-router-dom'
function EditCategoryTrails({ id }) {
	const navigate = useNavigate()
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const { openModal, closeModal, openError } = useModal()
	const [trails, setTrails] = useState([])

	const [open, setOpen] = useState(false)

	const form = useForm({
		initialValues: {
			title: '',
			description: '',
		},
		validate: {
			title: (value) => {
				if (!value.trim()) return 'Title cannot be empty'
				if (value.trim().length < 2 || value.length > 50)
					return 'Title must be 2-50 characters long'
				return null
			},
			description: (value) => {
				if (value.length > 255)
					return 'Description must be under 255 characters long'
				return null
			},
		},
		validateInputOnBlur: true,
		validateInputOnChange: true,
	})
	const handleEditCategoryTrails = useCallback(
		async (body, trail_ids) => {
			openLoadingContext()
			try {
				const [res1, res2] = await Promise.all([
					updateCategoryTrails({ category_id: id, trail_ids }),
					updateCategory(id, body),
				])
				if (res1?.data?.code === 200 && res2?.data?.code === 200) {
					openModal({
						type: 'success',
						label: 'Category has been edited successfully ✔️✔️',
						handleClose: () => {
							closeModal()
							navigate('/categorytrails')
						},
					})
				}
			} catch (error) {
				console.log(
					'🧑‍🏭 TrieuNinhHan ~ file: ModalEditCategoryTrails.tsx:68 ~ handleSubmit ~ error:',
					error,
				)
				openError(error)
			} finally {
				closeLoadingContext()
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[id],
	)
	const handleSubmit = useCallback(
		async (values) => {
			const body = {
				...values,
			}
			const trail_ids = trails?.map((item) => item?.id) || []
			openModal({
				type: 'confirm',
				label: 'Do you want to edit category ?',
				handleClose: () => closeModal(),
				handleConfirm: () => {
					handleEditCategoryTrails(body, trail_ids)
				},
			})
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[JSON.stringify(trails)],
	)
	const handleGetOne = useCallback(async () => {
		openLoadingContext()
		try {
			const res = await getOneCategory(id, {
				fields: [
					'$all',
					{
						category_trails: [
							'id',
							{
								trail: [
									'id',
									'user_id',
									'title',
									'start_address',
									'address',
									'description',
									'images',
									'level',
									'time_in_seconds',
									'height',
									'length',
									{ user: ['name', 'account_type'] },
								],
							},
						],
					},
				],
			})
			if (res?.data?.code === 200) {
				const { object } = res?.data?.results
				form.setValues({
					title: object?.title || '',
					description: object?.description || '',
				})
				setTrails(
					object?.category_trails?.map((trail) => ({ ...trail?.trail })) || [],
				)
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalEditCategoryTrails.tsx:106 ~ handleGetOne ~ error:',
				error,
			)
			openError(error)
		} finally {
			closeLoadingContext()
		}
	}, [JSON.stringify(id)])
	useEffect(() => {
		handleGetOne()
	}, [id])
	return (
		<Stack sx={{ width: '100%' }}>
			<CustomGoBack
				label="Category trails"
				onClick={() => {
					navigate('/categorytrails')
				}}
			/>
			<form
				onSubmit={form.onSubmit((values) => handleSubmit(values))}
				style={{ width: '100%' }}
			>
				<Stack spacing="0.75rem">
					<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
						Edit category
					</Flex>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="title"
						label={'Title'}
						name={'title'}
						placeholder={"Category's title"}
						width={'100%'}
						maxLength={50}
						{...form.getInputProps('title')}
					/>
					<Textarea
						autoComplete="off"
						key="description"
						label={'Description'}
						name={'name'}
						placeholder={"Category's description"}
						maxLength={255}
						autosize
						{...form.getInputProps('description')}
					/>
					<CustomButton
						type="button"
						variant="outlined"
						sx={{ width: '240px' }}
						onClick={() => setOpen(true)}
					>
						Add trails +
					</CustomButton>
					<Flex
						sx={{
							flexWrap: 'wrap',
						}}
						gap={10}
					>
						{trails?.map((trail) => (
							<TrailItem
								trail={trail}
								key={trail?.id}
								trails={trails}
								setTrails={setTrails}
							/>
						))}
					</Flex>
					<CustomButton
						variant="outlined"
						type="submit"
						sx={{ width: '240px' }}
					>
						Edit
					</CustomButton>
				</Stack>
			</form>
			{open && (
				<ModalTrails
					open={open}
					onClose={() => setOpen(false)}
					trails={trails}
					setTrails={setTrails}
				/>
			)}
		</Stack>
	)
}

export default React.memo(EditCategoryTrails)
