/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from '@mantine/form'
import { Flex, Image, Stack, Textarea, TextInput } from '@mantine/core'
import CustomButton from 'src/components/CustomComponents/CustomButton'
import { useModal } from 'src/context/ModalContext'
import {
	createCategoryTrails,
	updateCategory,
} from 'src/apis/categoryTrailsAPI'
import { useLoading } from 'src/context/LoadingContext'
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import axios from 'axios'

import CustomProgress from 'src/components/CustomComponents/CustomProgress'
import { uploadProgress } from 'src/apis/uploadApi'
import CustomModal from 'src/components/CustomComponents/CustomModal'

interface ModalCreateCategoryTrailsProps {
	open: any
	onClose: () => void
	handleGetData: () => void
	pagination: {
		pageIndex: number
		pageSize: number
	}
	handleChangePage: (_value: number) => void
	[key: string]: any
}

function ModalCreateTopic({
	open,
	onClose,
	handleGetData,
	pagination,
	handleChangePage,
}: ModalCreateCategoryTrailsProps) {
	const { openLoadingContext, closeLoadingContext } = useLoading()
	const [uploadedFile, setUploadedFile] = useState<File | null>(null)
	const [progress, setProgress] = useState(0)
	const [controller, setController] = useState<AbortController | null>(null)
	const Topic = open?.parent_id ? 'Sub topic' : 'Topic'
	const initialValues = {
		title: '',
		...(open?.parent_id ? { description: '', image: '' } : {}),
	}
	const validate = {
		title: (value) => {
			if (!value.trim()) return 'Title cannot be empty'
			if (value.trim().length < 2 || value.length > 50)
				return 'Title must be 2-50 characters long'
			return null
		},
		...(open?.parent_id
			? {
					description: (value) => {
						if (!value.trim()) return 'Description cannot be empty'
						if (value.trim().length < 2 || value.length > 255)
							return 'Description must be 2-255 characters long'
						return null
					},
					image: (value) => {
						if (!value.trim()) return 'Image cannot be empty'
					},
			  }
			: {}),
	}
	const form = useForm({
		initialValues: initialValues,
		validate: validate,
		validateInputOnBlur: true,
		validateInputOnChange: true,
	})
	const { openModal, closeModal, openError } = useModal()
	const handleCreateCategoryTrails = useCallback(async (body) => {
		openLoadingContext()
		try {
			const res = await createCategoryTrails(body)
			if (res?.data?.code === 200) {
				if (pagination.pageIndex === 1) {
					await handleGetData()
				} else {
					await handleChangePage(1)
				}

				openModal({
					type: 'success',
					label: `${Topic} discuss has been created successfully ✔️✔️`,
					handleClose: () => {
						closeModal()
						onClose()
					},
				})
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalCreateTopic.tsx:68 ~ handleSubmit ~ error:',
				error,
			)
			openError(error)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleUpdateCategoryTrails = useCallback(async (body) => {
		openLoadingContext()
		try {
			const res = await updateCategory(open?.id?.id, body)
			if (res?.data?.code === 200) {
				await handleGetData()
				openModal({
					type: 'success',
					label: `${Topic} discuss has been edited successfully ✔️✔️`,
					handleClose: () => {
						closeModal()
						onClose()
					},
				})
			}
		} catch (error) {
			console.log(
				'🧑‍🏭 TrieuNinhHan ~ file: ModalCreateTopic.tsx:68 ~ handleSubmit ~ error:',
				error,
			)
			openError(error)
		} finally {
			closeLoadingContext()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const handleUploadImage = async (files: File[]) => {
		if (controller) {
			controller.abort() // Hủy bỏ yêu cầu API
		}
		const newController = new AbortController()
		setController(newController)
		const signal = newController.signal
		const file = files[0]
		setUploadedFile(file)

		try {
			const formData = new FormData()
			formData.set('image', file)
			const uProgress = (progressEvent: ProgressEvent) => {
				const { loaded, total } = progressEvent
				const percentCompleted = Math.round((loaded * 100) / total)
				setProgress(percentCompleted)
			}
			const res = await uploadProgress(formData, uProgress, signal)
			setTimeout(() => {
				form.setFieldValue('image', res?.results?.object?.url)
			}, 1000)
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Upload canceled')
			} else {
				openError(error)
			}
		}
	}
	const handleSubmit = useCallback(async (values) => {
		const body = {
			...values,
			type: 'TOPIC_DISCUSS',
			...(open?.parent_id
				? {
						parent_id: open?.parent_id,
				  }
				: {}),
		}
		openModal({
			type: 'confirm',
			label: `Do you want to ${
				open?.id ? 'edit' : 'create'
			} ${Topic.toLocaleLowerCase()} discuss ?`,
			handleClose: () => closeModal(),
			handleConfirm: () => {
				open?.id
					? handleUpdateCategoryTrails(body)
					: handleCreateCategoryTrails(body)
			},
		})
	}, [])
	useEffect(() => {
		form.setValues({
			title: open?.id?.title || '',
			description: open?.id?.description || '',
			image: open?.id?.image || '',
		})
	}, [JSON.stringify(open?.id)])
	return (
		<CustomModal opened={open?.open} onClose={onClose}>
			<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
				<Stack spacing="0.75rem">
					<Flex sx={{ fontSize: 25, fontWeight: 700 }} justify="center">
						{open?.id ? 'Edit' : 'Create'} {Topic.toLowerCase()} discuss
					</Flex>
					<TextInput
						autoComplete="off"
						withAsterisk
						key="title"
						label={'Title'}
						name={'title'}
						placeholder={`${Topic} discuss's title`}
						maxLength={50}
						{...form.getInputProps('title')}
					/>
					{open?.parent_id && (
						<>
							<Textarea
								autoComplete="off"
								withAsterisk
								key="description"
								label={'Description'}
								name={'name'}
								placeholder={`${Topic} discuss's description`}
								maxLength={255}
								autosize
								{...form.getInputProps('description')}
							/>
							<>
								<label
									style={{
										display: 'inline-block',
										fontSize: '0.875rem',
										fontWeight: 500,
										color: '#212529',
									}}
								>
									Image
									<span style={{ color: '#fa5252' }}> *</span>
								</label>
								{progress > 0 && progress < 100 && (
									<CustomProgress value={progress} />
								)}
								<div>
									<Dropzone
										accept={IMAGE_MIME_TYPE}
										onDrop={handleUploadImage}
										multiple={false}
									>
										{uploadedFile || form.values.image ? (
											<Image
												key={form.values.image}
												src={form.values.image}
												alt="With default placeholder"
												withPlaceholder
												sx={{ img: { maxHeight: 300 } }}
												fit="contain"
											/>
										) : (
											<div>{'Drag and drop a file or click to browse.'}</div>
										)}
									</Dropzone>
									{!form.values.image && (
										<div
											style={{
												color: ' #fa5252',
												fontSize: ' calc(0.875rem - 0.125rem)',
											}}
										>
											{form.errors.image}
										</div>
									)}
								</div>
							</>
						</>
					)}
					<CustomButton variant="outlined" type="submit">
						{open?.id ? 'Edit' : 'Create'}
					</CustomButton>
				</Stack>
			</form>
		</CustomModal>
	)
}

export default React.memo(ModalCreateTopic)
