import { convertPhoneNumber } from './utils'

export const filterUserPageList = (datas) => {
	let filter = {}
	if (datas?.value) {
		if (datas?.field === 'phone' && datas?.value?.startsWith('0')) {
			const phones = convertPhoneNumber(datas?.value)
			filter = {
				...filter,
				$or: [
					...phones?.map((phone) => ({
						[datas?.field]: { $iLike: `%${String(phone).trim()}%` },
					})),
				],
			}
		} else {
			filter = {
				...filter,
				[datas?.field]: { $iLike: `%${String(datas?.value).trim()}%` },
			}
		}
	}
	return filter
}
export const filterNormal = (datas) => {
	let filter = {}
	if (datas?.value) {
		filter = {
			...filter,
			[datas?.field]: String(datas?.value).trim(),
		}
	}
	return filter
}
