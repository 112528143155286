import React from 'react'
import { Select, SelectProps } from '@mantine/core'
import { color } from 'src/utils/constant'

function CustomSelect({
	sx,
	...props
}: SelectProps & React.RefAttributes<HTMLInputElement>) {
	return (
		<Select
			sx={{
				minWidth: 240,
				height: 36,
				input: {
					':focus': {
						borderColor: color.CmsPrimary,
					},
				},
				'.mantine-Select-item': {
					'&[data-selected]': {
						backgroundColor: color.CmsPrimary,
					},
				},
				...sx,
			}}
			{...props}
		/>
	)
}

export default React.memo(CustomSelect)
