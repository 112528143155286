/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMantineTheme, Container, Grid, Text, Button, Group, Avatar, UnstyledButton, Anchor, Code, Image } from '@mantine/core';

const Footer = () => {
    const theme = useMantineTheme();
    
    return (
        <footer style={{ backgroundColor: theme.colors.green[6]}}>

            <Container>
                <Grid justify="space-around">

                    <Grid.Col xs={12} sm={8} md={8} lg={8}>
                        
                        <Text size="xl" weight={700} color="white" mb="10px">© 2024, VN CONNECTIONS COMPANY LIMITED</Text>

                        <Text color="white" mb="5px">
                            Address: 
                            2A Phan Tây Hồ, P7, Phú Nhuận, Ho Chi Minh City, Vietnam
                        </Text>

                        <Text color="white" mb="20px">
                            Email: <Anchor href="mailto:john@example.com">univini24@gmail.com</Anchor>
                            <br />
                            Phone: <Anchor href="tel:+84 913944449">+84 913944449</Anchor>
                            <br />
                            MST: <Anchor href="#">0317847483</Anchor>
                        </Text>

                        <Button variant="white" color="black" onClick={() => redirectToLink('https://vietnamhikers.com/policies/contact-information')}>Contract Us</Button>
                    </Grid.Col>

                    <Grid.Col xs={12} sm={4} md={4} lg={4}>
                        <Code color="green" style={{ display: 'flex', flexDirection: 'column', gap: 6, padding: 15 }}>
                            Our Company
                            <Anchor href="https://vietnamhikers.com/">
                                <UnstyledButton>
                                    <Group>
                                        {/* <Avatar size={40} color="green">VN Connections</Avatar> */}

                                        <Image src={require('src/images/logo.png')} alt={'logo'} style={{ width: '60px' }} />
                                        <div>
                                            <Text>Ms Thuong Zani </Text>
                                            <Text size="xs" color="dimmed">contact@vietnamhikers.com</Text>
                                        </div>
                                    </Group>
                                </UnstyledButton>
                            </Anchor>
                        </Code>
                    </Grid.Col>
                </Grid>
            </Container>
        </footer>
    )
};

export default Footer;

const redirectToLink = (link: string): void => {
    window.open(link, '_blank');
};