import React, { useRef } from 'react'
import { CloseButton, Flex, Input } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import { color } from 'src/utils/constant'

function CustomClearInput({ value, onChange, placeholder, sx = {}, ...props }) {
	const ref = useRef(null) as any
	return (
		<Input
			ref={ref}
			placeholder={placeholder ? placeholder : ''}
			value={value}
			onChange={(event) => onChange(event.currentTarget.value)}
			sx={{
				minWidth: 240,
				height: 36,
				input: {
					':focus': {
						borderColor: color.CmsPrimary,
					},
				},
				...sx,
			}}
			{...props}
			rightSection={
				value ? (
					<CloseButton
						aria-label="Clear input"
						onClick={() => {
							onChange('')
							ref?.current?.focus()
						}}
						style={{ display: value ? undefined : 'none' }}
					/>
				) : (
					<Flex>
						<IconSearch color={color.Black} />
					</Flex>
				)
			}
		/>
	)
}

export default React.memo(CustomClearInput)
