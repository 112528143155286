import React, { useMemo } from 'react'
import {
	MantineReactTable,
	MRT_ColumnDef,
	MRT_PaginationState,
} from 'mantine-react-table'
import { color } from 'src/utils/constant'
import { Flex, Pagination, Stack, Tooltip } from '@mantine/core'
import { IconEdit, IconInfoCircle, IconTrash } from '@tabler/icons-react'

interface TableComponentProps {
	total: number
	data: any
	handleChangePage: (value: number) => void
	handleEdit?: (row: any) => void
	handleDetail?: (row: any) => void
	handleDelete?: (row: any) => void
	pagination: MRT_PaginationState
	loading: boolean
	columns: MRT_ColumnDef[]
	enableRowActions?: boolean
	_renderCustomActions?: any
	[key: string]: any
}

function TableComponent({
	total,
	data,
	handleChangePage,
	loading = false,
	pagination,
	columns,
	enableRowActions = true,
	handleEdit,
	handleDetail,
	handleDelete,
	_renderCustomActions,
}: TableComponentProps) {
	const from = useMemo(
		() => pagination.pageSize * (pagination.pageIndex - 1) + 1,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[JSON.stringify(pagination)],
	)
	let to = useMemo(
		() => pagination.pageSize * (pagination.pageIndex - 1) + 10,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[JSON.stringify(pagination)],
	)

	if (to > total) {
		to = total
	}
	return (
		<Stack sx={{ width: '100%' }}>
			<MantineReactTable
				mantineProgressProps={{ color: color.CmsPrimary }}
				state={{ isLoading: loading }}
				initialState={{ showColumnFilters: false, showGlobalFilter: false }}
				columns={columns}
				data={data ?? []}
				mantinePaperProps={{ shadow: '0' }}
				enableEditing={false}
				enableSorting={false}
				enableColumnActions={false}
				enableTopToolbar={false}
				enableBottomToolbar={false}
				positionActionsColumn="last"
				renderRowActions={({ row }: any) => {
					return (
						<Flex gap={8}>
							<>
								{_renderCustomActions && _renderCustomActions?.(row)}
								{handleDetail && (
									<Stack sx={{ order: 10 }}>
										<Tooltip label="Detail">
											<IconInfoCircle
												style={{ cursor: 'pointer' }}
												onClick={() => handleDetail(row)}
											/>
										</Tooltip>
									</Stack>
								)}
								{handleEdit && (
									<Stack sx={{ order: 20 }}>
										<Tooltip label="Edit">
											<IconEdit
												style={{ cursor: 'pointer' }}
												onClick={() => handleEdit(row)}
											/>
										</Tooltip>
									</Stack>
								)}
								{handleDelete && (
									<Stack sx={{ order: 30 }}>
										{row?.original?.deleted_at ? (
											<IconTrash
												style={{ cursor: 'not-allowed', color: 'gray' }}
											/>
										) : (
											<Tooltip label="Delete">
												<IconTrash
													style={{ cursor: 'pointer', color: 'red' }}
													onClick={() => handleDelete(row)}
												/>
											</Tooltip>
										)}
									</Stack>
								)}
							</>
						</Flex>
					)
				}}
				mantineLoadingOverlayProps={{
					sx: {
						svg: {
							stroke: color.CmsPrimary,
						},
					},
				}}
				rowCount={total}
				mantineColumnActionsButtonProps={{
					sx: {
						background: 'red',
					},
				}}
				// renderTopToolbar={({}) => <></>}
				// renderBottomToolbar={({}) => <div>a</div>}
				// onPaginationChange={setPagination}
				enablePagination={false}
				// UI for Table
				mantineTableHeadCellProps={{
					sx: {
						'& .mantine-TableHeadCell-Content': {},
						background: '#FAFAFA',
					},
				}}
				// UI Cell
				mantineTableBodyCellProps={({ row }: any) => {
					return {
						sx: {
							background: row?.original?.deleted_at ? '#e4e4e4' : 'inherit',
							height: 50,
							padding: '5px 0 5px 16px !important',
							whiteSpace: 'nowrap',
							boxSizing: 'border-box',
						},
					}
				}}
				// UI head
				mantineTableHeadRowProps={() => {
					return {
						sx: {
							'.mantine-TableHeadCell-Content-Wrapper': {
								color: color.ColorBrandBlue,
								fontWeight: 500,
								whiteSpace: 'nowrap',
							},
							th: {
								paddingLeft: ' 16px !important',
								paddingRight: ' 0px !important',
							},
						},
					}
				}}
				//UI Body
				// mantineTableBodyProps={() => {
				// 	return {
				// 		sx: { height: 401 },
				// 	}
				// }}
				enableRowNumbers={false}
				enableRowActions={enableRowActions}
			/>
			{/* <Space h="sm" /> */}
			<Flex wrap="wrap" gap={16}>
				<span>
					Showing {from} to {to} from {total} entries
				</span>
				<Pagination
					sx={{
						flex: 1,
						minWidth: 300,
						button: {
							color: color.CmsPrimary,
							'&[data-active]': {
								color: 'white',
								background: color.CmsPrimary,
								':hover': {
									color: 'white',
									background: `${color.CmsPrimary} !important`,
								},
							},
						},
					}}
					position="right"
					total={Math.ceil(total / pagination.pageSize) || 1}
					onChange={(value) => {
						handleChangePage(value)
					}}
					value={pagination.pageIndex}
					disabled={loading}
				/>
			</Flex>
		</Stack>
	)
}

export default React.memo(TableComponent)
