import { MRT_ColumnDef } from 'mantine-react-table'
import { memo } from 'react'
import ConfigPhonePageList from 'src/containers/ConfigPhone'
import { size } from 'src/utils/constant'

function ConfigPhonePage() {
	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
			maxSize: size.text,
		},
		{
			accessorKey: 'value',
			header: 'Phone',
			size: size.no,
			maxSize: size.text,
		},
		{
			accessorKey: 'type',
			header: 'Type',
			size: size.no,
			maxSize: size.text,
		},
		{
			accessorKey: 'created_at',
			header: 'Created at',
			size: size.zero,
			maxSize: size.no,
		},
	]
	const fields = [
		{
			value: 'value',
			label: 'Phone',
		},
	]
	return <ConfigPhonePageList columns={columns} fields={fields} />
}

export default memo(ConfigPhonePage)
