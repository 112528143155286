import React, { useState } from 'react'
import { Flex } from '@mantine/core'
import { MRT_ColumnDef } from 'mantine-react-table'
import CustomTooltip from 'src/components/CustomComponents/CustomTooltip'
import CategoryTrailsPageList from 'src/containers/CategoryTrails/CategoryTrailsPageList'
import { pathUrl, size } from 'src/utils/constant'
import { useNavigate } from 'react-router-dom'

function TopicsPage() {
	const navigate = useNavigate()
	const [openTopic, setOpenTopic] = useState<any>({ open: false, id: null })

	const columns: MRT_ColumnDef[] = [
		{
			accessorKey: 'no',
			header: 'No',
			size: size.zero,
		},
		{
			accessorKey: 'title',
			header: 'Topics',
			size: size.zero,
			maxSize: size.text,
			Cell: ({ cell }) => {
				return (
					<CustomTooltip label={cell.getValue<string>()} maxWidth={size.text} />
				)
			},
			enableResizing: false,
		},
		// {
		// 	accessorKey: 'description',
		// 	header: 'Description',
		// 	size: size.zero,
		// 	maxSize: size.long,
		// 	Cell: ({ cell }) => {
		// 		return (
		// 			<CustomTooltip label={cell.getValue<string>()} maxWidth={size.long} />
		// 		)
		// 	},
		// },
		// {
		// 	accessorKey: 'image',
		// 	header: 'Image',
		// 	size: size.zero,
		// 	maxSize: size.long,
		// 	Cell: ({ cell }) => {
		// 		return (
		// 			<Stack>
		// 				<Image
		// 					src={cell.getValue<string>()}
		// 					height={cell.getValue<string>() ? 100 : 0}
		// 					width={'auto'}
		// 					sx={{ maxWidth: 100 }}
		// 					fit="contain"
		// 				/>
		// 			</Stack>
		// 		)
		// 	},
		// },
		{
			accessorKey: 'created_at',
			header: 'Created at',
			size: size.zero,
			maxSize: size.text,
		},
	]
	const fields = [
		{
			value: 'title',
			label: 'Title',
		},
	]
	const handleEdit = (row: any) => {
		setOpenTopic({ open: true, id: row?.original })
	}
	const handleDetail = (row: any) => {
		navigate(`${pathUrl.topics}/${row?.original?.id}`)
	}
	return (
		<Flex sx={{ width: '100%' }}>
			<CategoryTrailsPageList
				columns={columns}
				fields={fields}
				handleEdit={handleEdit}
				type="TOPIC_DISCUSS"
				openTopic={openTopic}
				setOpenTopic={setOpenTopic}
				handleDetail={handleDetail}
			/>
		</Flex>
	)
}

export default React.memo(TopicsPage)
