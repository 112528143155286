/* eslint-disable @typescript-eslint/no-unused-vars */
import { Text, Container, Anchor, MediaQuery, Button, Image } from '@mantine/core';
import { color } from 'highcharts';
import { MdOutlineArrowDownward } from "react-icons/md";
import { Link } from 'react-scroll';

const About = () => {
    //const theme = useMantineTheme();

    return (
        <section id="about">
            <Container fluid size={"xl"}>

                <div className="about-content">

                    <div style={{ marginBottom: 15 }}>
                        {/* <Text 
                        // transform="uppercase" 
                        weight={500} color="green">
                            UniVini
                        </Text> */}


                    </div>

                    <div style={{ marginBottom: 15, maxWidth: '800px'}}>
                        <Text>
                            <MediaQuery query="(max-width: 768px)" styles={{ fontSize: '2.8rem !important' }}>
                                <h1 className="title">Explore & Connect</h1>
                            </MediaQuery>
                        </Text>
                        
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <Image src={require('src/images/onboarding-1.png')} alt={'onboarding1'} style={{ width: '30%', marginRight: '10px' }} /> */}
                            {/* <Image src={require('src/images/onboarding-2.png')} alt={'onboarding2'} style={{ width: '30%', marginRight: '10px' }} /> */}
                            {/* <Image src={require('src/images/onboarding-3.png')} alt={'onboarding3'} style={{ width: '30%', marginRight: '10px' }} /> */}
                            {/* <Image src={require('src/images/onboarding-4.png')} alt={'onboarding4'} style={{ width: '30%', marginRight: '10px' }} /> */}
                            <Image src={require('src/images/about/about-1.png')} alt={'onboarding1'} style={{ width: '40%', marginRight: '3px' }} />
                            <Image src={require('src/images/about/about-2.png')} alt={'onboarding2'} style={{ width: '40%', marginRight: '3px' }} />
                            <Image src={require('src/images/about/about-3.png')} alt={'onboarding3'} style={{ width: '40%', marginRight: '3px' }} />
                            <Image src={require('src/images/about/about-4.png')} alt={'onboarding3'} style={{ width: '40%', marginRight: '3px' }} />
                            <Image src={require('src/images/about/about-5.png')} alt={'onboarding5'} style={{ width: '40%', marginRight: '3px' }} />

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        </div>
                    </div>

                    <div style={{ marginBottom: 25 }}>
                        <Text size="xl" color="black">
                        The easiest way to
                        Practice Languages,
                        Build Networks,
                        Travel 
                        <hr />
                        <Anchor color="green" href="#link-app">
                            Welcome to <span  style={{ color: 'green!important',}}>UniVini</span>
                        </Anchor>
                        {/* <Image src={require('src/images/logo.png')} alt={'logo'} style={{ width: '60px' }} /> */}
                        </Text>
                    </div>

                    <div className="buttons">
                        <Link to="section-one" smooth duration={500}>
                            <Button color="yellow" rightIcon={<MdOutlineArrowDownward size={16} />} radius="lg" size="md">Tell me more</Button>
                        </Link>

                        <Button  component="a" variant="default" radius="lg" size="md"  href="#link-app">Download Now!</Button>
                    </div>

                </div>

            </Container>

        </section>
    );
};

export default About;