import { Accordion, Text, Container, Title, Badge } from '@mantine/core';
import { FiCoffee, FiCloudSnow, FiAnchor } from "react-icons/fi";

const SectionFive = () => {
    //const theme = useMantineTheme();

    return (
        <section id="section-five">
            <Container>
                <div style={{ marginBottom: 30 }}>
                    <div style={{ textAlign: 'left' }}><Badge variant="filled" color="green">FAQs</Badge></div>
                    <Text color="black">
                        <Title order={1} style={{ marginTop: 10 }}>Woah, Frequently asked questions here</Title>
                    </Text>
                </div>

                <Accordion variant="contained">
                    <Accordion.Item value="item1">
                        <Accordion.Control icon={<FiCoffee size={20} color={"#fab005"} />}>
                            What is UniVini?
                        </Accordion.Control>
                        <Accordion.Panel>It is a social app that connects locals, expats, and travelers through hangouts, social events, communities, business networks, and travel experiences.</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="item2">
                        <Accordion.Control icon={<FiCloudSnow size={20} color={"#fab005"} />}>
                            Is it free?
                        </Accordion.Control>
                        <Accordion.Panel>
                        Yes, it is completely free. We’ve been passionate about doing this for six years, organizing social events every day in Saigon. Our clubs are called the Roji Social Club and Vietnam Hikers. Now, with UniVini app, we hope to connect people more easily throughout Vietnam and beyond.
                        <br/>
                        Our team is working hard on it, and we hope you enjoy the app. The app is new, and we’re happy to receive any feedback or contributions!
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="item3">
                        <Accordion.Control icon={<FiAnchor size={20} color={"#fab005"} />}>
                            Who can join UniVini Network
                        </Accordion.Control>
                        <Accordion.Panel>Everyone who loves learning languages, meeting people, socializing, helping, and growing a trusted community, and who prefers to travel in an authentic way, discovering natural hidden gems.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </section>
    );

};

export default SectionFive;